@use "../../Assets/abstracts/mixins";
@import "../../Assets/abstracts/variables";
.room {

  &_hero {

    .prev-arrow {
      background-color: white;
      padding: 12px 16px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
      display: flex !important;
      align-items: center;
      left: auto;
      position: absolute;
      top: 50%;
      left: 32px;
      z-index: 12;
      &::before {
        font-size: 40px;
      }
    }
  
    .next-arrow {
      background-color: white;
      padding: 12px 16px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
      display: flex !important;
      align-items: center;
      left: auto;
      position: absolute;
  
      top: 50%;
      right: 32px;
      &::before {
        font-size: 40px;
      }
    }
    .slick-slider {
      width: 100%;
      height: 100% !important;
      img{
        height: 57vh;
        object-fit: cover;
      }
    }
    .slick-dots {
      bottom: 30px;
    }
    .slick-slider {
      width: 100%;
      overflow: hidden;
    }
    .slick-dots li button:before {
      color: white !important;
    }
    .slick-dots li button:before {
      font-size: 10px;
    }
    @include mixins.bgimg;
    height: 57vh;
    @include mixins.respond(md) {
 height: 400px;
    }
  }
  &_details {
    background-color: white;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 65px;
      @include mixins.respond(md) {
        width: 100%;
            }
    }
    &-content {
      width: 60%;
      @include mixins.respond(md) {
        width: 100%;
            }
      h2 {
        margin-bottom: 35px;
      }
    }
    &-form {
      width: 35%;
      @include mixins.respond(md) {
       display: none;
           }
    }
    &-form-mobile {
      width: 95%;
      margin: 0 auto;
      margin-bottom: 45px;

      .rdrDefinedRangesWrapper{
        display: none;
      }
      @media (min-width: 992px) {
      display: none;

    }}

    &_info {
      margin-top: 35px;
      margin-bottom: 55px;
      display: grid;
      width: 80%;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 0px;
      grid-column-gap: 0;
      color: $lightBlack;
      @include mixins.respond(md){
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 10px;
        width: 100%;
      }
      &-item {
      
      svg {
        margin-right: 8px;
        path {
          fill: #9e9e9e !important;
        }
     
      }
      span{
        @include mixins.respond(md){
         font-size: 13px;
        }
      }
    }
  }
    &-rules {
      h2 {
        font-weight: 700;
        @include mixins.hh(18, 18, 18, 18, #1a1b1a, "w-light");
      }
      span {
        font-weight: 400;
        @include mixins.hh(17, 17, 17, 17, #1a1b1a, "w-light");
      }
      ul {
        margin-top: 15px;
        padding-left: 15px;
        @include mixins.respond(md){
          width: 80%;
         }

      }
      ul li {
        margin-bottom: 15px;
        font-size: 17px;
        @include mixins.respond(md){
          font-size: 16px;
         }
      }
      ul li::marker {
        color: $buttonHover;
      }
      p{
        padding-right: 15px;
      }
    }
    &-extra {
      margin-top: 55px;
      h2 {
        margin-top: 35px;
        @include mixins.hh(25, 25, 24, 24, #1a1b1a, "w-light");
      }
      &-container {
        display: grid;
        // width: 80%;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 20px;
        grid-column-gap: 20px;
        @include mixins.respond(md) {
          grid-template-columns: repeat(1, 1fr);
              }
      }
      &-item {
        background-color: $lightbrown;
        padding: 30px 15px;
        display: flex;
        align-items: center;
        @include mixins.respond(md){
          width: 95%;
          margin: 0 auto;
        }
        span {
          margin-top: 4px;
        }
        svg {
          margin-right: 12px;
          height: 27px;
        }
        svg path {
          fill: $buttonHover;
        }
      }
    }
  }
  &_amenities {
    background-color: white;
    padding-top: 50px;
    padding-bottom: 35px;
    @include mixins.respond(md) {
      padding-top: 60px;
    }
    &-container {
      display: grid;
      margin-top: 35px;

      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 0px;
      grid-column-gap: 0;
      @include mixins.respond(md) {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 15px;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      h3{
        @include mixins.hh(25, 25, 24, 24, #1a1b1a, "w-light");
      }
      @include mixins.respond(md) {
       
        width: 100%;
      }
    }
    &-item {
      margin-bottom: 20px;
      svg {
        margin-right: 12px;
        height: 20px;
      }
      svg path {
        fill: $buttonHover;
      }
    }
  }

  &_included {
    background-color: white;
    padding: 35px 0px;
    @include mixins.respond(md) {
padding-top: 0px
    }
    h2{
      @include mixins.hh(25, 25, 24, 24, #1a1b1a, "w-light");
    }
    ul {
      margin-top: 15px;
      padding-left: 15px;
    }
    ul li {
      margin-bottom: 15px;
      @include mixins.respond(md) {
       margin-bottom: 10px;
       font-size: 17px;
            }
    }
    ul li::marker {
      color: $buttonHover;
      font-size: 22px;
    }
  }
}
.room_datePicker {
  .rdrDays{
    width: 600px;
    @include mixins.respond(md){
      width: 100%;
    }
  }
  .rdrMonth .rdrWeekDays{
    width: 600px;
    @include mixins.respond(md){
      width: 100%;
    }
  }
  .rdrDateDisplayItem:first-child{
    display:none !important;
  }
  .rdrDefinedRangesWrapper{
    display: none;
  }
  @include mixins.respond(md){
    padding-bottom: 100px;
  }
  .rdrDateRangePickerWrapper {
    @include mixins.respond(md){
      display: flex;
      flex-direction: column;
    }

  }


  .rdrMonths {
  @include mixins.respond(md){
    display: flex;
    flex-direction: column !important;
  }
}


  background-color: white;
  padding-bottom: 10px;
  &-actions {
    display: flex;
    justify-content: end;
    align-items: end;
    width: 100%;
    padding-right: 130px;
    margin-top: 20px;
    border-top: 1px solid rgb(216, 212, 212);
    padding-top: 30px;
    @include mixins.respond(md){
      padding-right: 0px;
    }
    button {
      margin-left: 12px;
    }
    .cancel-btn {
      background-color: white;
      border: 1px solid black;
      font-weight: 500;
      font-size: 11px;
      color: black;
      padding: 5px 16px;
      text-transform: uppercase;
    }
    .apply-btn {
      background-color: black;
      border: 1px solid black;
      font-weight: 500;
      font-size: 11px;
      color: white;
      padding: 5px 22px;
      text-transform: uppercase;
    }
  }

  .rdrDateDisplayWrapper {
    background-color: transparent;
  }
  .rdrEndEdge {
    color: $buttonHover !important;
    border: none !important;
    border-radius: none;
  }
  .rdrStartEdge {
    color: $buttonHover !important;
    border: none !important;
    border-radius: none;
  }
  .rdrDayHovered {
    border: none !important;
    border-radius: 0px !important;
  }
  .rdrEndEdge {
    border-radius: 0px !important;
  }

  .rdrDayToday {
    color: $buttonHover !important;
    border: none !important;
  }
  h2 {
    margin-bottom: 35px;
  }
  &-wrapper {
    width: 80%;
    padding-bottom: 40px;
  }
}

// .roomDetails {
//   &_similar-area {
//     width: 100%;
//     .roomDetails_similar-lists {
//       padding-top: 45px !important;
//     }

//     .roomDetails_similar .item-card {
//       height: 400px !important;
//     }
//   }
//   background-color: white;
//   padding: 60px 0px;
//   &_price {
//     background-color: white;
//     z-index: 15;
//     position: absolute;
//     top: 15px;
//     left: 15px;
//     padding: 7px 10px;
//     width: fit-content;
//     font-size: 14px;
//   }
//   &-content {
//     width: 100%;
//   }
//   &-lists {
//     display: grid;
//     margin-top: 45px;
//     grid-template-columns: repeat(3, 1fr);
//     grid-row-gap: 20px;
//     grid-column-gap: 45px;
//   }
//   &-item {
//     height: 420px;
//     position: relative;
//     &-content {
//       padding: 20px;
//       align-items: start;
//       height: 100%;
//       justify-content: end;
//       color: white;
//     }
//     &-more {
//       padding: 20px;
//       align-items: start;
//       height: 100%;
//       justify-content: end;
//       color: white;
//     }
//   }

//   .item-card {
//     width: 100%;
//     position: relative;
//     overflow: hidden;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//     height: 400px;
//   }

//   .card-image {
//     position: relative;
//     width: 100%;
//     cursor: pointer;
//     height: 100%;
//   }

//   .image-front {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     transition: opacity 0.3s ease-in-out;
//   }

//   .hover-content {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     opacity: 0;
//     display: flex;
//     flex-direction: column;
//     justify-content: end;
//     align-items: center;
//     background: #545d48d6;
//     color: #fff;
//     transition: opacity 0.3s ease-in-out;
//   }

//   .card-image:hover .hover-content {
//     opacity: 1;
//   }

//   .card-image:hover .image-front {
//     opacity: 0;
//   }

//   .card-image:hover .hover-text {
//     bottom: 0px;
//     @include mixins.slow;
//   }

//   .image-back {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     display: none;
//   }

//   .hover-text {
//     cursor: pointer;
//     position: absolute;
//     bottom: -30px;
//     padding: 20px;
//     a {
//       border-bottom: 1px solid $buttonHover;
//       font-weight: 400;
//       font-size: 15px;
//       padding-bottom: 4px;
//     }
//   }

//   .hover-text p {
//     margin: 10px 0;
//   }

//   .discover-more {
//     color: white;
//     text-decoration: none;
//     font-weight: 500;
//     border-bottom: 1px solid $lightbrown;
//   }

//   .card-details {
//     padding: 10px;
//     opacity: 1;
//     text-align: center;
//     position: absolute;
//     display: flex;
//     justify-content: end;
//     align-items: flex-start;
//     color: white;
//     padding: 20px;
//     flex-direction: column;
//     height: 100%;
//     width: 100%;
//   }

//   .card-details p {
//     margin: 5px 0;
//   }

//   .card-image:hover .card-details {
//     opacity: 0;
//   }
// }



.roomDetails2_similar {
  .card-details h2{
    font-size: 26px;
  }

  background-color: white;
  // padding: 60px 0px;
  position: relative;
  padding-bottom: 100px;
&-area{
  width: 100%;
}
  &_icon {
    transform: rotate(180deg);
    overflow: hidden;
    position: absolute;
    top: -26px;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    @include mixins.respond(md){
      top:-15px;
    } 
    svg {
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      @include mixins.respond(md){
        height: 15px;
        width: auto;
      }
      path {
        fill: white;
      }
    }
  }
  &_price {
    background-color: white;
    z-index: 15;
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 7px 10px;
    width: fit-content;
    color:black !important;
    font-size: 14px;
  }
  &-content {
    width: 100%;
  }
  &-lists {
    display: grid;
    padding-top: 35px;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 45px;
    @include mixins.respond(md){
      grid-template-columns: repeat(1, 1fr);
      padding-top: 45px;
    }
  }
  &-item {
    height: 403px;
    position: relative;
    &-content {
      padding: 20px;
      align-items: start;
      height: 100%;
      justify-content: end;
      color: white;
    }
    &-more {
      padding: 20px;
      align-items: start;
      height: 100%;
      justify-content: end;
      color: white;
    }
  }

  .room_similar {
    background-color: white;
    padding: 60px 0px;
    &_price {
      background-color: white;
      z-index: 15;
      position: absolute;
      top: 15px;
      left: 15px;
      padding: 7px 10px;
      width: fit-content;
      font-size: 14px;
    }
    &-content {
      width: 100%;
    }
    &-lists {
      display: grid;
      margin-top: 45px;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 45px;
      @include mixins.respond(md){
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &-item {
      height: 420px;
      position: relative;
      &-content {
        padding: 20px;
        align-items: start;
        height: 100%;
        justify-content: end;
        color: white;
      }
      &-more {
        padding: 20px;
        align-items: start;
        height: 100%;
        justify-content: end;
        color: white;
      }
    }
  }

  .item-card {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 403px;
    border-radius: 0px;
    @include mixins.slow;
    @include mixins.respond(md){
      height: 460px;
    }
    .item-card-hover {
      width: 100%;
      height: 100%;
      @include mixins.bgimg;
      @include mixins.slow;
      background-position: left top !important; 
    }
    &:hover .item-card-hover {
      transform: scale(1.05);
    }
  }

  .card-image {
    position: relative;
    width: 100%;
    cursor: pointer;
    height: 100%;
    :hover .item-card-hover {
      transform: scale(1.2);
    }
  }

  .image-front {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }

  .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background: #545d48d6;
    color: #fff;
    transition: opacity 0.3s ease-in-out;
  }

  .card-image:hover .hover-content {
    opacity: 1;
  }

  .card-image:hover .image-front {
    opacity: 0;
  }

  .card-image:hover .hover-text {
    bottom: 0px;
    @include mixins.slow;
  }

  .image-back {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }

  .hover-text {
    cursor: pointer;
    position: absolute;
    bottom: -30px;
    padding: 30px;
    a {
      border-bottom: 1px solid $buttonHover;
      font-weight: 400;
      font-size: 15px;
      padding-bottom: 4px;
    }
  }

  .hover-text p {
    margin: 10px 0;
  }

  .discover-more {
    color: white;
    text-decoration: none;
    font-weight: 500;
    border-bottom: 1px solid $lightbrown;
  }

  .card-details {
    padding: 10px;
    opacity: 1;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    color: white;
    padding: 20px;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .card-details p {
    margin: 5px 0;
  }

  .card-image:hover .card-details {
    opacity: 0;
  }
}

.rooms-included2{
  display: grid;
  width: 80%;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0px;
  grid-column-gap: 140px;
}

.bedroom-data{
  display: grid;
  width: 80%;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0px;
  grid-column-gap: 140px;
  h4{
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 18px;
  }

}

.bedrooms_info h3{
  margin-bottom: 6px;
  font-size: 22px;
}

.bedroom-inner{
 
  span{
    font-weight: 600 !important;
    margin-right: 6px

  }
}

.room_included-wrapper{
  width: 100%;
svg{
  margin-right: 7px;
  path{
    fill:#ab916c;
  }
}
ul{
  display: grid;
      margin-top: 35px;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 0px;
      grid-column-gap: 0;
      @include mixins.respond(md) {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 15px;
      }
}
}

.error-message {
  color: red;
  margin-bottom: 1em;
  padding: 0.5em;
  border: 1px solid red;
  border-radius: 4px;
  background-color: #ffe6e6;
}

