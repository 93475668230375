
@import 'variables';

// ========== Default Title =================
@mixin defaultTitle($color, $align, $margin) {
  font-size: 35px;
  font-weight: bold;
  color: $color;
  margin: $margin;
  text-align: $align;
  @include respond(sm){
    font-size: 22px;
  }
  @include respond(xs){
	text-align: center;
}
}

// ================ Media Query ====================

// 	xs  -  480px
// 	sm  -  768px"
//  md  -  992px"
//  lg  -  1200px"
//  xl  -  1600px"


@mixin hh($size1, $size2, $size3, $size4, $color, $family) {
	font-size: #{$size1}px;
	font-family: #{$family};
	color: $color;
	@media (max-width: 1600px) {
		font-size: #{$size2}px;
	}
	@include respond(xl) {
		font-size: #{$size3}px;
	}
	@include respond(sm) {
		font-size: #{$size4}px;
	}
}

@mixin respond($breakpoint) {
	@if $breakpoint == xs {
		@media (max-width: 480px) {
			@content;
		}
	}
	@if $breakpoint == sm {
		@media (max-width: 768px) {
			@content;
		}
	}
	@if $breakpoint == md {
		@media (max-width: 992px) {
			@content;
		}
	}
	@if $breakpoint == lg {
		@media (max-width: 1200px) {
			@content;
		}
	}
	@if $breakpoint == xl {
		@media (max-width: 1400px) {
			@content;
		}
	}
	@if $breakpoint == xxl {
		@media (max-width: 1600px) {
			@content;
		}
	}
	@if $breakpoint == 2xl {
		@media (max-width: 1900px) {
			@content;
		}
	}
	@if $breakpoint == mn {
		@media (min-width: 992px) { @content };
	}
}

// @media (min-width: 1400px) {
//   .container {
// 	max-width: 1340px;
//   }
// }
// @media (min-width: 1600px) {
//   .container {
// 	max-width: 1540px;
//   }
// }





// =============== Width-Height ==================
@mixin size($width, $height) {
	width: $width;
	height: $height;
}

// =============== Margin Auto ==================
@mixin mx-auto($auto) {
	margin-left: $auto !important;
	margin-right: $auto !important;
}
@mixin xauto {
	margin-left: auto !important;
	margin-right: auto !important;
}
@mixin yauto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}


// Background Image
@mixin bgimg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin slow {
	
    transition: transform 0.4s;
  }
  




@mixin button{
	background-color: $main;
    padding: 20px 25px;
    color: white;


	font-size: 18px;
	font-family: "light";
	padding: 15px 42px;
    transition: all 0.5s ease-in-out;
	border:none !important;

	
		&:hover {
			transition: all 0.5s ease-in-out;
			background-color: $gray;
			color: white;
			background-color:$buttonHover;
		}

		@include respond(md){
			padding: 11px 21px;
			font-size: 18px;
		}
		@include respond(sm){
			font-size: 18px;
			padding: 11px 21px;
		}
	}

	
@mixin kontakt-button{
	background-color: $gray;
    padding: 15px 39px;
    border-radius: 25px;
    color: white;
	font-size: 17px;
	font-family: "regular";
    transition: all 0.5s ease-in-out;
		&:hover {
			transition: all 0.5s ease-in-out;
			background-color: white;
			color: $gray;
		}

		@include respond(sm){
			font-size: 16px;
			padding: 12px 25px;
		}
	}


	@mixin load-button{
		background-color: transparent;
		padding: 15px 39px;
		border:1px solid gray;
		border-radius: 4px;
		color: $gray;
		font-size: 15px;
		font-family: "regular";
		transition: all 0.5s ease-in-out;
		width: fit-content;
		text-transform: uppercase;
			&:hover {
				border:1px solid transparent;
				transition: all 0.5s ease-in-out;
				background-color: $gray;
				color: white;
			}
			@include respond(sm){
				padding: 12px 25px;
			}
		}
 



		
@mixin borderButton{
	background-color: white;
    padding: 20px 25px;
    color: black;
	height: 52px;

	font-size: 18px;
	font-family: "light";
	padding: 0px 42px;
    transition: all 0.5s ease-in-out;
	border:1px solid black;

	
		&:hover {
			transition: all 0.5s ease-in-out;
			background-color: $buttonHover;
			color: white;
			border:none;
			border:1px solid transparent;
			
		}

		@include respond(md){
			padding: 12px 15px;
			font-size: 16px;
		}
		@include respond(sm){
			padding: 11px 15px;
		}
	}