@use "../../Assets/abstracts/mixins";
@import "../../Assets/abstracts/variables";


.faq-container {
    width: 80%;
    margin: 0 auto;
    @include mixins.respond(md) {
      width: 97%;
       }
    h1{
        margin-bottom: 55px;
        @include mixins.hh(22, 21, 21, 19, black, "");
        font-weight: 700 !important;
        @include mixins.respond(md) {
            margin-bottom: 25px;
             }
    }
  }
  
  .faq-item {
display: flex;
align-items: start;
flex-direction: column;

    padding: 15px 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.0784313725);
    border-right: 1px solid rgba(0, 0, 0, 0.0784313725);
    border-left: 1px solid rgba(0, 0, 0, 0.0784313725);
    svg path{
        fill:gray;
    }
    &:last-child{
        border-bottom: 1px solid rgba(0, 0, 0, 0.0784313725);
    }
  }
  
  .faq-question {
    background: none;
    border: none;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-answer {
    margin-top: 5px;

  }
  
  .arrow {
    font-size: 14px; 
    margin-left: 10px; 
  }
  
  .faq{
    height: 89vh;
    background-color: white;
    margin-top: 96px;
    padding-bottom: 50px;
    padding-top: 50px;
    @include mixins.respond(md) {
      margin-top: 66px;
      }
    h2{
        @include mixins.hh(20, 19, 19, 19, black, "");
    }
    @include mixins.respond(md) {
     height: auto;
     padding: 50px 0px;
      }
  }