
@use "../../../Assets/abstracts/mixins";
@import "../../../Assets/abstracts/variables";

.booking-form {
    font-family: Arial, sans-serif;
    margin: auto;
    padding: 30px;
    box-shadow: 0 5px 30px rgba(80, 57, 24, 0.15);
    padding-top: 55px;
    border-radius: 10px;
}

.booking-form h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.booking-form p {
    font-size: 18px;
}

.booking-form div {
    margin-bottom: 15px;
}

.booking-bottom{display: flex;
    margin-bottom: 20px;
justify-content: space-between;
align-items: center;
span{
    font-weight: 500;
    font-size: 22px;
}

}
.booking-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-size: 15px;
    }
}

.booking-form label {
    display: block;
    margin-bottom: 5px;
}

.booking-form input,
.booking-form select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border:1px solid $buttonHover;
}

.booking-form button {
   
    padding: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.booking-person{
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px !important;
    >div{
        width: 48%;
    }
    h3{
        margin-bottom: 30px;
    }
}

.booking-services{
width: 100%;display: flex;
justify-content: space-between;
margin-bottom: 15px !important; 
align-items: center;
input{
    width: fit-content;
}
span{
    font-size:15px;
}
>div{
    width: 47%;
    display: flex;
    align-items: center;
    label{
        margin-bottom: 0px;
        margin-left: 6px;
    }
  
}
&:last-child{
    border-bottom:1px solid lightgray;
    margin-bottom: 25px !important;
}
}

.custom-datepicker {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
}

.custom-datepicker-wrapper {
    width: 100%;
    display: inline-block;
}


.submitReservation{
    width: 100%;
}

h2.react-datepicker__current-month{
    font-size: 18px;
}

.react-datepicker__day--highlighted-custom {
    background-color: #f9c74f; /* for example */
    color: #000;
  }

.react-datepicker__day--highlighted-custom {
    color: #ccc;  // Muted text color similar to disabled dates
    background-color: white;  // Light grey background
 }
  