$main:#53624e;
$text:#1a1b1a;
$submenu:#090909;
$gray:#e0e1df;
$buttonHover:#ab916c;
$lightBlack:#333632;
$lightbrown:#FAF7F4;
$lightgray:#F3F4F0;


