@use "../../Assets/abstracts/mixins";
@import "../../Assets/abstracts/variables";

.about_rules{
  background-color: #53624e;
}

.about_hero {
  @include mixins.bgimg;
  height: 89vh;
  margin-top: 81px;

  &_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    h2 {
      font-weight: 500;
      @include mixins.hh(85, 65, 55, 48, white, "w-light");
      margin-top: 25px;
      margin-bottom: 25px;
      @include mixins.respond(md) {
        text-align: center;
      }
    }
    span {
      @include mixins.hh(21, 19, 19, 18, white, "w-light");
    }
    p {
      margin-top: 20px;
      @include mixins.hh(21, 20, 20, 20, white, "w-light");
      @include mixins.respond(md) {
        text-align: center;
      }
    }
    .main-btn {
      margin-top: 30px;
    }
  }

  &_checkAvaibility {
    transform: translateY(-70px);
    &-wrapper {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 0;
      background-color: #f6f5f3;
      padding: 60px 0;
      box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.13);
      padding: 40px 40px 40px 40px;
      background-color: #f8f5f1;
      @include mixins.respond(md) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &-box {
      display: flex;
      justify-content: start;
      align-items: center;

      padding: 10px 0;
      &:nth-child(1) {
        border-right: 1px solid $buttonHover;

        @include mixins.respond(md) {
          padding-left: 0px;
          border-right: none;
        }
      }
      &:nth-child(2) {
        border-right: 1px solid $buttonHover;
        padding-left: 20px;
        @include mixins.respond(md) {
          padding-left: 0px;
          border-right: none;
        }
      }
      &:nth-child(3) {
        padding-left: 20px;
        @include mixins.respond(md) {
          padding-left: 0px;
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        margin-left: 17px;
      }
      svg {
        width: 20px;
        height: auto;
        path {
          fill: $buttonHover;
        }
      }
      h5 {
        font-weight: 500;
        margin-bottom: 8px;
        @include mixins.hh(19, 19, 19, 19, $text, "w-light");
      }
      .main-btn {
        padding-left: 45px;
        padding-right: 45px;
      }
    }
  }
}

.about_welcome {
  background-color: $gray;
  padding-top: 100px;
  @include mixins.respond(md) {
    padding-bottom: 40px;
    transform: translateY(-100px);
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0px;
    span {
      text-transform: uppercase;
      margin-bottom: 20px;

      @include mixins.hh(19, 17, 13, 13, $lightBlack, "w-light");
    }
    h2 {
      text-transform: uppercase;
      margin-bottom: 30px;
      width: 60%;
      line-height: 57px;
      margin: 0 auto;
      @include mixins.hh(40, 35, 25, 19, $lightBlack, "w-light");
      @include mixins.respond(md){
        line-height: 30px;
      }
    }

    p {
      width: 60%;
      margin: 0 auto;
      margin-top: 30px;
      @include mixins.hh(19, 19, 19, 19, $lightBlack, "w-light");
      margin-bottom: 55px;
      @include mixins.respond(md){
        width: 90%;
      }
    }
  }
  &-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    padding: 60px 0px;
    @include mixins.respond(md) {
      grid-template-columns: repeat(1, 1fr);
      padding: 20px 0px;
    
    }
    padding-bottom: 120px;
  }
  &-box {
    @include mixins.bgimg;
    padding: 10px !important;
    height: 70vh;

    @include mixins.respond(md) {
      height: 400px;
    }
    &-container {
      border: 1px solid $buttonHover;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      @include mixins.respond(md) {
      }
      p {
        text-transform: uppercase;
        margin-bottom: 12px;
        @include mixins.hh(19, 19, 19, 19, white, "w-light");
      }
      h1 {
        font-weight: 500;
        @include mixins.hh(55, 50, 35, 35, white, "w-light");
      }
      button {
        background-color: transparent;
        font-size: 16px;
        border: none;
        color: white;
        border-bottom: 1px solid $buttonHover;
        margin-top: 20px;
      }
    }
  }
}

.about_counting {
  padding: 140px 0px;
  @include mixins.bgimg;
  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    height: 100%;
    width: 100%;
    h2 {
      width: 70%;
      margin: 0 auto;
      text-align: center;
      @include mixins.hh(55, 50, 35, 27, white, "w-light");
      margin-top: 35px;
      @include mixins.respond(md){
        width: 90%;
      }
    }
  }
  &-list {
    margin-top: 25px;
    display: grid;
    text-align: center;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0;
    width: 40%;
    @include mixins.respond(md){
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &-item span {
    @include mixins.hh(75, 65, 65, 45, white, "");
  }
}
.about_book {
  background-color: white;
  &_container {
    display: flex;
    justify-content: space-between;
    padding: 100px 0px;
    align-items: center;
    width: 100%;
    @include mixins.respond(md){
      flex-direction: column-reverse;
    }
  }
  &_left {
    width: 45%;
    @include mixins.respond(md){
     width: 100%;
     margin-top: 45px;
    }
    span {
      font-weight: 600;
      text-transform: uppercase;
      @include mixins.hh(14, 14, 14, 14, #53624e, "");
    }
    h2 {
      margin-top: 20px;
      width: 80%;
      @include mixins.hh(48, 48, 35, 35, black, "w-light");
      margin-bottom: 20px;
      @include mixins.respond(md){
        width: 90%;
       }
    }
    p {
      @include mixins.hh(19, 19, 19, 19, black, "w-light");
      margin-bottom: 25px;
    }
  }
  &_right {
    width: 50%;
    @include mixins.respond(md){
      width: 100%;
     }

    .prev-arrow2 {
      background-color: white !important;
      padding: 12px 15px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
      z-index: 12;
      display: flex !important;
      align-items: center;
      @include mixins.slow;
      right: 62px;
      position: absolute;
      top: -57px;
      border: 1px solid lightgray;
      left: auto;
      &::before {
        font-size: 40px;
      }
    }
    .slick-dots {
      opacity: 0;
    }

    .next-arrow2 {
      background-color: white;
      padding: 12px 15px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
      display: flex !important;
      align-items: center;
      left: auto;
      border: 1px solid lightgray;
      position: absolute;
      @include mixins.slow;
      top: -57px;
      right: 12px;
      z-index: 12;

      &::before {
        font-size: 40px;
      }
    }
    .item-card {
      width: 100%;
      position: relative;
      overflow: hidden;
      height: 502px;
      border-radius: 0px;
      @include mixins.slow;
      .item-card-hover {
        width: 100%;
        height: 100%;
        @include mixins.bgimg;
        @include mixins.slow;
      }
      &:hover .item-card-hover {
        transform: scale(1.05);
      }
    }

    .card-image {
      position: relative;
      width: 100%;
      cursor: pointer;
      height: 100%;
      :hover .item-card-hover {
        transform: scale(1.2);
      }
    }

    .image-front {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.3s ease-in-out;
    }

    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      background: #545d48d6;
      color: #fff;
      transition: opacity 0.3s ease-in-out;
    }

    .card-image:hover .hover-content {
      opacity: 1;
    }

    .card-image:hover .image-front {
      opacity: 0;
    }

    .card-image:hover .hover-text {
      bottom: 0px;
      @include mixins.slow;
    }

    .image-back {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;
    }

    .hover-text {
      cursor: pointer;
      position: absolute;
      bottom: -30px;
      padding: 30px;
      a {
        z-index: 12;
        background-color: transparent;
        font-size: 13px;
        border: none;
        position: relative;
        color: white;
        cursor: pointer;
        padding-bottom: 6px;
        border: none;
        text-decoration: none;
        margin-top: 20px;
        text-transform: capitalize;
        svg {
          transition: transform 0.2s ease;
          transform-origin: 0 0;
          height: 10px;
          transform: translateX(6px);
        }
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background: $buttonHover !important;

          content: "";
          transition: transform 0.2s ease;
          transform-origin: 0 0;
        }
        &:hover svg {
          transition: transform 0.2s ease;
          transform-origin: 0 0;
          transform: translateX(13px);
        }
      }
      a:hover:before {
        transform: scaleX(0);
      }

      a span {
        position: relative;
      }
    }

    .hover-text p {
      margin: 10px 0;
    }

    .discover-more {
      color: white;
      text-decoration: none;
      font-weight: 500;
      border-bottom: 1px solid $lightbrown;
    }

    .card-details {
      padding: 10px;
      opacity: 1;
      text-align: center;
      position: absolute;
      display: flex;
      justify-content: end;
      align-items: flex-start;
      color: white;
      padding: 20px;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    .card-details p {
      margin: 5px 0;
    }

    .card-image:hover .card-details {
      opacity: 0;
    }
  }
}
.about_experience {
  background-color: white;
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 100px;
    @include mixins.respond(md) {
    flex-direction: column;
    padding-bottom: 0px;
    }
  }
  &_left {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0px;
    grid-column-gap: 0px;
    @include mixins.respond(md) {
      width: 100%;
      grid-template-columns: repeat(1, 1fr);
    }
    > div {
      height: 570px;
      
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &:nth-child(1) {
        padding: 20px 0px;
      }
      &:nth-child(2) {
        margin: 0px 0px 0px -53px;
        @include mixins.respond(md) {
          margin: 0px;
        }
      }
    }
  }
  &_right {
    width: 45%;
    @include mixins.respond(md){
      width: 100%;
      padding-top: 45px;
    }
    span {
      font-weight: 600;
      text-transform: uppercase;
      @include mixins.hh(14, 14, 14, 14, #53624e, "");
    }
    h2 {
      margin-top: 20px;
      width: 80%;
      @include mixins.hh(48, 48, 35, 35, black, "w-light");
      margin-bottom: 20px;
    }
    p {
      @include mixins.hh(19, 19, 19, 19, black, "w-light");
      margin-bottom: 25px;
    }
  }
}
.about_services {
  background-color: $lightgray;
  padding: 100px 0px;
  &_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0px;
    span {
      text-transform: uppercase;
      margin-bottom: 20px;
      @include mixins.hh(19, 19, 19, 14, $lightBlack, "w-light");
    }
    h2 {
      text-transform: uppercase;
      margin-bottom: 30px;
      width: 60%;
      line-height: 57px;
      margin: 0 auto;
      @include mixins.hh(40, 35, 35, 30, $lightBlack, "w-light");
      @include mixins.respond(md){
        width: 100%;
        line-height: 37px;
 
      }

    }

    p {
      width: 60%;
      margin: 0 auto;
      margin-top: 30px;
      @include mixins.hh(19, 19, 19, 19, $lightBlack, "w-light");
      margin-bottom: 35px;
        
           @include mixins.respond(md){
            width: 90%;
    
     
          }
    }
  }
  &-list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-top: 20px;

    @include mixins.respond(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .cs-item-container {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  .cs-info-box-img {
    flex: 0 0 auto;
    margin-right: 16px;
    line-height: 0;
  }
  .cs-info-box-item {
    padding: 30px;
    background-color: white;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #00000012;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .cs-info-box-img svg {
    height: 40px;
  }
  .cs-info-box-img svg path {
    fill: #b99d75;
  }

  .cs-info-box-content {
    position: relative;
    flex: 1 1 auto;
    h5 {
      font-weight: 500;
      margin-bottom: 12px;
      @include mixins.hh(25, 22, 22, 20, black, "w-light");
    }
    p {
      font-weight: 400;

      line-height: 25px;
      margin-bottom: 0px;
      @include mixins.hh(17, 17, 17, 17, black, "w-light");
    }
  }
}
.about_discover {
  height: 100vh;
  width: 100%;
  @include mixins.respond(md){
    height: 100%;
  }
  &_container {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0px;
    grid-column-gap: 0px;
    @include mixins.respond(md){
      grid-template-columns: repeat(1, 1fr);
      height: 100%;

    }
  }
  &_item {
    height: 100vh;
    @include mixins.bgimg;
    &_content {
      height: 50vh;
      @include mixins.bgimg;
      width: 100%;
    }
    .white {
      background-color: white;
      // width: 100%;
      // height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h2 {
        font-weight: 500;
        @include mixins.hh(35, 35, 30, 25, black, "w-light");
        margin-top: 25px;
        margin-bottom: 25px;
        @include mixins.respond(md) {
          text-align: center;
        }
      }
      span {
        text-transform: uppercase;
        font-weight: 600;
        @include mixins.hh(13, 13, 13, 13, $main, "");
      }
      p {
        margin-top: 20px;
        text-align: center;
        width: 80%;
        margin: 0 auto;
        @include mixins.hh(21, 19, 19, 18, black, "w-light");
        @include mixins.respond(md) {
          text-align: center;
        }
      }
      a {
        z-index: 12;
        background-color: transparent;
        font-size: 15px;
        border: none;
        position: relative;
        color: black;
        cursor: pointer;
        border: none;
        text-decoration: none;
        margin-top: 20px;
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background: $buttonHover !important;

          content: "";
          transition: transform 0.2s ease;
          transform-origin: 0 0;
        }
      }

      a:hover:before {
        transform: scaleX(0);
      }
    }
    .discover_text {
      display: flex;
      justify-content: end;
      align-items: center;
      flex-direction: column;
      padding-bottom: 50px;
      h2 {
        font-weight: 500;
        @include mixins.hh(35, 35, 30, 25, white, "w-light");
        margin-top: 25px;
        margin-bottom: 25px;
        @include mixins.respond(md) {
          text-align: center;
        }
      }
      span {
        text-transform: uppercase;
        @include mixins.hh(15, 15, 15, 15, white, "w-light");
      }
      p {
        margin-top: 20px;
        text-align: center;
        width: 80%;
        margin: 0 auto;
        @include mixins.hh(21, 19, 19, 18, white, "w-light");
        @include mixins.respond(md) {
          text-align: center;
        }
      }
      a {
        z-index: 12;
        background-color: transparent;
        font-size: 15px;
        border: none;
        position: relative;
        color: white;
        cursor: pointer;
        border: none;
        text-decoration: none;
        margin-top: 20px;
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background: $buttonHover !important;

          content: "";
          transition: transform 0.2s ease;
          transform-origin: 0 0;
        }
      }

      a:hover:before {
        transform: scaleX(0);
      }
    }
  }
}

.about_villa_info {
  display: grid;

  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 10px;
@include mixins.respond(md){
  grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 6px;
}
  &-item {
    align-items: center;
    display: flex;
    a {
    }
  }
  svg {
    margin-right: 6px;
    path {
      fill: #ffffff;
    }
  }
}


.about_hero {
  position: relative;
  height: calc(100vh - 80px);
  overflow: hidden;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(19, 19, 19, 0.192), rgba(56, 56, 56, 0.322)); 
    z-index: 1;
  }

  @include mixins.respond(md) {
    height: calc(100vh - 70px);
  }

  &_video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  &_placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    visibility: visible;
  }

  &_video[autoplay] + &_placeholder {
    visibility: hidden;
  }

  &_content {
    position: relative;
    z-index: 2; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;
  }
}

