.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .scroll-button {
    background-color: #b99d75 !important; 
    color: white;
    border: none;
    border-radius:3px;
    border:1px solid #b99d75;
    padding: 5px 10px;
    cursor: pointer;
    // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    svg path{
        fill:white;
    }
  }
  
  .scroll-button:hover {
    background-color: #b99d75; 
  }
  
  .scroll-button:focus {
    outline: none;
  }
  