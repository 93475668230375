@use "../../Assets/abstracts/mixins";
@import "../../Assets/abstracts/variables";

.roomDetails_hero {
  @include mixins.bgimg;
  height: calc(90vh - 180px);
  @include mixins.respond(md) {
    height: calc(100vh - 52px);
  }
  &_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    h2 {
      font-weight: 500;
      @include mixins.hh(85, 65, 55, 45, white, "w-light");
      @include mixins.respond(md) {
        text-align: center;
      }
    }
    p {
      margin-top: 20px;
      @include mixins.hh(21, 19, 19, 18, white, "w-light");
      @include mixins.respond(md) {
        text-align: center;
      }
    }
    .main-btn {
      margin-top: 30px;
    }
  }

  &_checkAvaibility {
    background-color: #f8f5f1;
    &-wrapper {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 0;
      padding: 60px 0;

      padding: 40px 40px 40px 40px;

      @include mixins.respond(md) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &-box {
      display: flex;
      justify-content: start;
      align-items: center;

      padding: 10px 0;
      &:nth-child(1) {
        border-right: 1px solid $buttonHover;

        @include mixins.respond(md) {
          padding-left: 0px;
          border-right: none;
          border-bottom: 1px solid $buttonHover;
        }
      }
      &:nth-child(2) {
        border-right: 1px solid $buttonHover;
        padding-left: 20px;
        @include mixins.respond(md) {
          padding-left: 0px;
          border-right: none;
          border-bottom: 1px solid $buttonHover;
        }
      }
      &:nth-child(3) {
        padding-left: 20px;
        @include mixins.respond(md) {
          padding-left: 0px;
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        margin-left: 17px;
      }
      svg {
        width: 20px;
        height: auto;
        path {
          fill: $buttonHover;
        }
      }
      h5 {
        font-weight: 500;
        margin-bottom: 8px;
        @include mixins.hh(19, 19, 19, 19, $text, "w-light");
      }
      .main-btn {
        padding-left: 85px;
        padding-right: 85px;
        height: 57px;
        font-size: 19px;
        display: flex;
        align-items: center;
        text-decoration: none;
        @include mixins.respond(md){
          justify-content: center;
          width: 100%;
          padding:0px 34px;
          margin-top: -20px;
        }
      }
    }
  }
}
.roomDetails_welcome {
  background-color: white;
  padding: 100px 0px;
  @include mixins.respond(md){
    padding-top: 60px;
    padding-bottom: 40px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0px;
    span {
      text-transform: uppercase;
      margin-bottom: 20px;
      @include mixins.hh(19, 17, 13, 13, $lightBlack, "w-light");
    }
    h2 {
      text-transform: uppercase;
      margin-bottom: 30px;
      width: 60%;
      line-height: 57px;
      margin: 0 auto;
      @include mixins.hh(40, 35, 35, 24, $lightBlack, "w-light");
      @include mixins.respond(md) {
        line-height: 30px;
        width: 80%;
      }
    }

    p {
      width: 60%;
      margin: 0 auto;
      margin-top: 30px;
      @include mixins.hh(19, 19, 19, 19, $lightBlack, "w-light");
      margin-bottom: 35px;
      @include mixins.respond(md) {
        width: 80%;
      }
    }
  }
  &_actions {
    display: flex;
    @include mixins.respond(md) {
 flex-direction: column;
    }
    a {
      margin-right: 15px;
      border:1px solid #53624e;
      @include mixins.respond(md) {
        margin-bottom: 12px;
      }

    }
  }
}

.roomDetails_banner {
  @include mixins.bgimg;
  padding: 130px 0px;
  @include mixins.respond(md) {
    padding: 100px 0px;
  }
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    @include mixins.respond(md) {
     text-align: center;
    }
    span {
      text-transform: uppercase;
      margin-bottom: 20px;
      @include mixins.hh(19, 19, 19, 13, white, "w-light");
    }
    h2 {
      margin-bottom: 25px;

      line-height: 57px;
      margin: 0 auto;
      @include mixins.hh(40, 35, 35, 30, white, "w-light");
    }

    p {
      margin: 0 auto;
      margin-top: 25px;
      @include mixins.hh(19, 19, 19, 20, white, "w-light");
      margin-bottom: 0px;
      @include mixins.respond(md) {
        margin-top: 15px;
       }
    }
  }
}
.roomDetails_roomSlide {
  position: relative;
  &_icon {
    transform: rotate(180deg);
    overflow: hidden;
    position: absolute;
    top: -26px;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    @include mixins.respond(md){
      top:-15px;
    } 
    svg {
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      @include mixins.respond(md){
        height: 15px;
        width: auto;
      }
      path {
        fill: white;
      }
    }
  }
  background-color: white;
  padding: 100px 0px;
  @include mixins.respond(md) {
   padding-bottom: 20px;
  }
  &-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .home_gallery_top {
    height: fit-content;
    h4 {
      margin-bottom: 15px;
      @include mixins.hh(19, 19, 19, 19, #333632, "w-light");
    }
    h1 {
      margin-bottom: 25px;
      font-weight: 500;
      @include mixins.hh(65, 65, 40, 35, #333632, "w-light");
    }
    p {
      margin-bottom: 35px;
      line-height: 25px;
      width: 80%;
      @include mixins.hh(19, 19, 19, 19, #333632, "w-light");
      @include mixins.respond(md) {
        width: 100%;
      }
    }
  }
  body {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 60px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
  }
  h3 {
    font-size: 40px;
    background-color: red;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 150px 0;
    margin: 0px auto;
    font-family: sans-serif;
    margin: 0 50px;
  }
  .slick-next {
    position: absolute;
    top: -40px;
    right: 50px;
    font-size: 22px;
    &::before {
      font-size: 40px;
    }
  }
  .slick-prev {
    left: auto;
    position: absolute;
    top: -40px;
    font-size: 22px;
    right: 124px;
    &::before {
      font-size: 40px;
    }
  }
  .slick-dots {
    bottom: -65px;
  }

  img {
    display: block;
    height: 430px;
    width: 100%;
    object-fit: cover;
    @include mixins.respond(md){
     height: 250px;
    }
  }
  .room_villa_content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    h2 {
      margin-bottom: 10px;
      font-weight: 500;
      margin-top: 25px;
      @include mixins.hh(35, 35, 30, 25, #1a1b1a, "w-light");
    }
    p {
      line-height: 24px;
      width: 90%;
      @include mixins.hh(17, 17, 17, 17, #6c6767, "w-light");
      margin-bottom: 0px;
    }
    a {
      z-index: 12;
      background-color: transparent;
      font-size: 13px;
      border: none;
      position: relative;
      color: #6c6767;
      cursor: pointer;
      padding-bottom: 6px;
      border: none;
      text-decoration: none;
      margin-top: 20px;
      text-transform: capitalize;
      svg {
        transition: transform 0.2s ease;
        transform-origin: 0 0;
        height: 10px;
        transform: translateX(6px);
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: $buttonHover !important;

        content: "";
        transition: transform 0.2s ease;
        transform-origin: 0 0;
      }
      &:hover svg {
        transition: transform 0.2s ease;
        transform-origin: 0 0;
        transform: translateX(13px);
      }
    }

    a:hover:before {
      transform: scaleX(0);
    }
  }

  .room_villa_box {
    width: 48%;
    margin-bottom: 55px;
    position: relative;
    text-decoration: none;
    color: unset;
    @include mixins.respond(md){
      width: 100%;
    }

    .prev-arrow2 {
      background-color: white !important;
      padding: 10px 14px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
      z-index: 12;
      display: flex !important;
      align-items: center;
      @include mixins.slow;
      left: 12px;
      position: absolute;
      top: 50%;
      opacity: 0;
      left: 12px;
      &::before {
        font-size: 40px;
      }
    }

    .next-arrow2 {
      background-color: white;
      padding: 10px 14px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
      display: flex !important;
      align-items: center;
      left: auto;
      position: absolute;
      @include mixins.slow;
      top: 50%;
      right: 12px;
      opacity: 0;
      &::before {
        font-size: 40px;
      }
    }
    .slick-dots {
      bottom: 23px;
    }

    .slick-dots li button:before {
      color: white !important;
      font-size: 8px;
    }

    &:hover {
      .prev-arrow2 {
        opacity: 1 !important;
      }
      .next-arrow2 {
        opacity: 1 !important;
      }
    }
  }

  .room_villa_img {
    overflow: hidden;
    cursor: pointer;
    @include mixins.slow;
    img {
      transition: transform 0.5s, filter 1.5s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.04);
        cursor: pointer;
      }
    }
  }
  .room_villa_info {
    display: grid;
    width: 90%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0;
    margin-bottom: 10px;
   
    &-top {
      color: #6c6767;
      font-size: 14px;
      margin-bottom: 12px;
    }
    &-item {
      color: #6c6767;
      font-size: 14px;
    }
    svg {
      margin-right: 6px;
      path {
        fill: #9e9e9e !important;
      }
    }
    .room_similar_price {
      background-color: white;
      z-index: 15;
      position: absolute;
      top: 15px;
      left: 15px;
      padding: 7px 10px;
      width: fit-content;
      font-size: 14px;
    }
  }
}

.roomDetails_similar {
  background-color: white;
  // padding: 60px 0px;
  position: relative;
  padding-bottom: 100px;

  &_icon {
    transform: rotate(180deg);
    overflow: hidden;
    position: absolute;
    top: -26px;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    @include mixins.respond(md){
      top:-15px;
    } 
    svg {
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      @include mixins.respond(md){
        height: 15px;
        width: auto;
      }
      path {
        fill: white;
      }
    }
  }
  &_price {
    background-color: white;
    z-index: 15;
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 7px 10px;
    width: fit-content;
    font-size: 14px;
    color:black !important;
  }
  &-content {
    width: 100%;
  }
  &-lists {
    display: grid;
    padding-top: 105px;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 45px;
    @include mixins.respond(md){
      grid-template-columns: repeat(1, 1fr);
      padding-top: 85px;
    }
  }
  &-item {
    height: 420px;
    position: relative;
    &-content {
      padding: 20px;
      align-items: start;
      height: 100%;
      justify-content: end;
      color: white;
    }
    &-more {
      padding: 20px;
      align-items: start;
      height: 100%;
      justify-content: end;
      color: white;
    }
  }

  .room_similar {
    background-color: white;
    padding: 60px 0px;
    &_price {
      background-color: white;
      z-index: 15;
      position: absolute;
      top: 15px;
      left: 15px;
      padding: 7px 10px;
      width: fit-content;
      font-size: 14px;
    }
    &-content {
      width: 100%;
    }
    &-lists {
      display: grid;
      margin-top: 45px;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 45px;
      @include mixins.respond(md){
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &-item {
      height: 420px;
      position: relative;
      &-content {
        padding: 20px;
        align-items: start;
        height: 100%;
        justify-content: end;
        color: white;
      }
      &-more {
        padding: 20px;
        align-items: start;
        height: 100%;
        justify-content: end;
        color: white;
      }
    }
  }

  .item-card {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 600px;
    border-radius: 0px;
    @include mixins.slow;
    @include mixins.respond(md){
      height: 460px;
    }
    .item-card-hover {
      width: 100%;
      height: 100%;
      @include mixins.bgimg;
      @include mixins.slow;
    }
    &:hover .item-card-hover {
      transform: scale(1.05);
    }
  }

  .card-image {
    position: relative;
    width: 100%;
    cursor: pointer;
    height: 100%;
    :hover .item-card-hover {
      transform: scale(1.2);
    }
  }

  .image-front {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }

  .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background: #545d48d6;
    color: #fff;
    transition: opacity 0.3s ease-in-out;
  }

  .card-image:hover .hover-content {
    opacity: 1;
  }

  .card-image:hover .image-front {
    opacity: 0;
  }

  .card-image:hover .hover-text {
    bottom: 0px;
    @include mixins.slow;
  }

  .image-back {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }

  .hover-text {
    cursor: pointer;
    position: absolute;
    bottom: -30px;
    padding: 30px;
    a {
      border-bottom: 1px solid $buttonHover;
      font-weight: 400;
      font-size: 15px;
      padding-bottom: 4px;
    }
  }

  .hover-text p {
    margin: 10px 0;
  }

  .discover-more {
    color: white;
    text-decoration: none;
    font-weight: 500;
    border-bottom: 1px solid $lightbrown;
  }

  .card-details {
    padding: 10px;
    opacity: 1;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    color: white;
    padding: 20px;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .card-details p {
    margin: 5px 0;
  }

  .card-image:hover .card-details {
    opacity: 0;
  }
}

.roomDetails_bannerBottom {
  @include mixins.bgimg;
  padding: 130px 0px;
  @include mixins.respond(md) {
    height: calc(100vh - 70px);
  }
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    @include mixins.respond(md) {
      text-align: center;
    }
    span {
      text-transform: uppercase;
      margin-bottom: 20px;
      @include mixins.hh(19, 19, 19, 13, white, "w-light");
 
    }
    h2 {
      margin-bottom: 25px;

      line-height: 57px;
      margin: 0 auto;
      @include mixins.hh(40, 35, 30, 30, white, "w-light");
      @include mixins.respond(md) {
        line-height: 37px;
      }
    }

    p {
      width: 80%;
      text-align: center;
      margin: 0 auto;
      margin-top: 25px;
      @include mixins.hh(19, 19, 19, 19, white, "w-light");
      margin-bottom: 0px;
      @include mixins.respond(md){
        width: 90%;
        }
    }
  }
  .roomDetails-checkIn {
    display: flex;
    margin-top: 35px;
    align-items: center;
    color: white;
    > div {
      margin-right: 20px;
      @include mixins.respond(md){
      display: flex;
    flex-direction: column;
      }
      span{
        margin-bottom: 0px;
        text-transform: none;
        font-size: 18px;
      }
      &:first-child {
        border-right: 1px solid white;
        padding-right: 20px;
      }
      svg {
        margin-right: 6px;
        @include mixins.respond(md){
         margin-bottom: 4px;
          }
        path {
          fill: $buttonHover;
        }
      }
    }
  }
}

.border-link {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 12px;
  justify-content: center;
}

.home_hero_checkAvaibility-wrapper2 {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 0;
  background-color: #f6f5f3;
  padding: 60px 0;

  padding: 40px 40px 40px 40px;
  background-color: #f8f5f1;
  @include mixins.respond(md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.react-datepicker-wrapper input::placeholder{
  font-size: 15px;
}

.react-datepicker-wrapper input{
  padding: 3px 5px;
}