@use "../../Assets/abstracts/mixins";
@import "../../Assets/abstracts/variables";

body {
  background-color: $gray;
}
.home_hero {
  @include mixins.bgimg;
  height: calc(100vh - 80px);
  @include mixins.respond(md) {
    height: calc(100vh - 70px);
  }
  &_button{
    display: flex;
    button{
      margin-right:7px;
      margin-top: 36px;
      @include mixins.respond(md) {
  margin-right:0px;
  margin-bottom:12px;
    }
 
  &:nth-child(2){
      background-color: #b99d75;
      @include mixins.respond(md) {
        margin-top: 6px;
       }
      
    }
  }
  @include mixins.respond(md) {
    flex-direction: column;
   }
}
  &_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    h2 {
      font-weight: 500;
      @include mixins.hh(85, 65, 51, 51, white, "w-light");
      @include mixins.respond(md) {
        text-align: center;
      }
    }
    p {
      margin-top: 20px;
      @include mixins.hh(21, 20, 20, 20, white, "w-light");
      @include mixins.respond(md) {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        line-height: 32px;
        margin-top: 20px;
      }
    }
 
  }

  &_checkAvaibility {

    margin: 0 auto;
    margin: 30px auto 0;
    @include mixins.respond(md) {
      // transform: translateY(28px);
    }
    &-wrapper {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 0;
      background-color: #f6f5f3;
      padding: 60px 0;
      box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.13);
      padding: 40px 40px 40px 40px;
      background-color: #f8f5f1;
      z-index: 122;
      @include mixins.respond(md) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &-box {
      display: flex;
      justify-content: start;
      align-items: center;

      padding: 10px 0;
      input{
        background-color: transparent !important;
        margin-top: 5px;
        border:1px solid #bab5b5 !important;
        padding: 3px;
      }
      &:nth-child(1) {
        border-right: 1px solid $buttonHover;

        @include mixins.respond(md) {
          padding-left: 0px;
          border-right: none;
        }
      }
      &:nth-child(2) {
        border-right: 1px solid $buttonHover;
        padding-left: 20px;
        @include mixins.respond(md) {
          padding-left: 0px;
          border-right: none;
        }
      }
      &:nth-child(3) {
        padding-left: 20px;
        svg {
          width: 27px;
        }
        @include mixins.respond(md) {
          padding-left: 0px;
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        margin-left: 17px;
      }
      svg {
        width: 20px;
        height: auto;
        path {
          fill: $buttonHover;
        }
      }
      h5 {
        font-weight: 500;
        margin-bottom: 8px;
        @include mixins.hh(19, 19, 19, 19, $text, "w-light");
      }
      .main-btn {
        padding-left: 75px;
        padding-right: 75px;
        display: flex;
        align-items: center;
        text-decoration: none;
        @include mixins.respond(md) {
          text-align: center;
        }
      }
    }
  }
}

.home_about {
  background-color: $gray;
  height: 100vh;
  margin-top: -90px;
  z-index: 0;
  @include mixins.respond(md) {
    margin-top: -30px;
    height: auto;
    
  }
  &_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2c3529;
    opacity: 0.8;
  }
  &_overlay2 {
    position: absolute;
    width: 95%;
    height: 97%;
    border: 1px solid $buttonHover;
    opacity: 0.8;
    z-index: 12;
  }
  &-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0px;
    grid-column-gap: 0;
    height: 73vh;

    @include mixins.respond(md) {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 60px;
      grid-row-gap: 10px;
      height: auto;
       padding: 0px;
    }
  }
  &-box {
    @include mixins.bgimg;
    position: relative;
    padding: 10px !important;
    height: 100%;
    @include mixins.respond(md) {
      height: 369px;
    }
    &-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      z-index: 12;
      @include mixins.respond(md) {
      }
      p {
        text-transform: uppercase;
        z-index: 12;
        margin-bottom: 12px;
        @include mixins.hh(19, 19, 16, 11, white, "w-light");
        @include mixins.respond(md) {
          margin-bottom: 13px;
        }

      }
      h1 {
        z-index: 12;
        font-weight: 500;
        @include mixins.hh(45, 45, 35, 27, white, "w-light");
        @include mixins.respond(md) {
         width: 70%;
        }
      }
      .link-button {
        z-index: 12;
        background-color: transparent;
        font-size: 16px;
        border: none;
        position: relative;
        color: white;
        cursor: pointer;
        border: none;
        text-decoration: none;
        margin-top: 20px;
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background: $buttonHover !important;

          content: "";
          transition: transform 0.2s ease;
          transform-origin: 0 0;
        }
      }

      .link-button:hover:before {
        transform: scaleX(0);
      }
    }
  }
}

.slider_box {
  height: 400px !important;

  width: 100%;
  @include mixins.bgimg;
}

.home_gallery_villa {
  padding-bottom: 130px;
  margin: 0 auto;
  width: 100%;
  @include mixins.respond(md) {
    padding-bottom: 100px;
    }
  .slick-slider {
    width: 100%;
  }

  .container {
    height: fit-content;
  }
  .home_gallery_top {
    height: fit-content;
    @include mixins.respond(md) {
     padding-top: 80px;
     }
    h4 {
      margin-bottom: 15px;
      font-weight: 600;
      @include mixins.hh(13, 13, 13, 13, $main, "");
    }
    h1 {
      margin-bottom: 25px;
      font-weight: 500;
      @include mixins.hh(55, 55, 30, 27, black, "w-light");
    }
    p {
      margin-bottom: 50px;
      line-height: 27px;
      width: 50%;
      @include mixins.hh(20, 20, 20, 19, #515050, "w-light");
      @include mixins.respond(md) {
        width: 100%;
        margin-bottom: 100px;
      }
    }
  }
  body {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 60px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
  }
  h3 {
    font-size: 40px;
    background-color: red;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 150px 0;
    margin: 0px auto;
    font-family: sans-serif;
    margin: 0 50px;
  }
  .slick-next {
    position: absolute;
    top: -40px;
    right: 50px;
    font-size: 22px;
    &::before {
      font-size: 40px;
    }
  }
  .slick-prev {
    left: auto;
    position: absolute;
    top: -40px;
    font-size: 22px;
    right: 124px;
    &::before {
      font-size: 40px;
    }
  }

  .prev-arrow {
    background-color: white !important;
    padding: 12px 16px;
    border-radius: 50%;
    border: none;
    font-size: 16px;
    display: flex !important;
    align-items: center;
    left: auto;
    position: absolute;
    top: -60px;

    right: 64px;
    &::before {
      font-size: 40px;
    }
  }

  .next-arrow {
    background-color: white;
    padding: 12px 16px;
    border-radius: 50%;
    border: none;
    font-size: 16px;
    display: flex !important;
    align-items: center;
    left: auto;
    position: absolute;

    top: -60px;
    right: 10px;
    &::before {
      font-size: 40px;
    }
  }

  .slick-dots {
    bottom: -65px;
  }

  .slick-slide img {
    display: block;
    height: 330px;
    width: 97%;
    object-fit: cover;
    @include mixins.respond(md){
      height: 270px;
    }
    
  }
  .home_villa_content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    h2 {
      margin-bottom: 20px;
      font-weight: 500;
      margin-top: 25px;
      @include mixins.hh(35, 35, 30, 25, #1a1b1a, "w-light");
    }
    p {
      line-height: 24px;
      width: 90%;
      @include mixins.hh(17, 17, 17, 17, #4e514e, "w-light");
    }

    a {
      z-index: 12;
      background-color: transparent;
      font-size: 15px;
      border: none;
      position: relative;
      color: #4e514e;
      cursor: pointer;
      border: none;
      text-decoration: none;
      margin-top: 10px;
      svg {
        transition: transform 0.2s ease;
        transform-origin: 0 0;
        height: 12px;
        transform: translateX(8px);
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: $buttonHover !important;

        content: "";
        transition: transform 0.2s ease;
        transform-origin: 0 0;
      }
      &:hover svg {
        transition: transform 0.2s ease;
        transform-origin: 0 0;
        transform: translateX(13px);
      }
    }

    a:hover:before {
      transform: scaleX(0);
    }

    a span {
      position: relative;
    }
  }
  .home_villa_info {
    display: grid;
    width: 80%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0;
    margin-bottom: 15px;
    @include mixins.respond(md) {
      width: 100%;
      }
    span {
      font-size: 15px;
    }
    svg {
      height: 15px;
      margin-right: 6px;
      path {
        fill: #4e514e !important;
      }
    }
  }
}

.home_services {
  background-color: $main;

  padding: 100px 0px;
  &-list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 40px;
    grid-column-gap: 20px;

    @include mixins.respond(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .cs-item-container {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  .cs-info-box-img {
    flex: 0 0 auto;
    margin-right: 16px;
    line-height: 0;
    @include mixins.respond(md) {
      margin-right: 36px;
    }
  }
  .cs-info-box-img svg {
    height: 40px;
  }
  .cs-info-box-img svg path {
    fill: #b99d75;
  }

  .cs-info-box-content {
    position: relative;
    flex: 1 1 auto;
    h5 {
      font-weight: 500;
      margin-bottom: 12px;
      @include mixins.hh(25, 22, 22, 20, white, "w-light");
    }
    p {
      font-weight: 400;
      width: 80%;
      line-height: 25px;
      margin-bottom: 12px;
      @include mixins.hh(17, 17, 17, 17, white, "w-light");
    }
  }
}

.home_activity {
  padding-bottom: 70px;
  padding-top: 50px;
  &-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 55px;
    p {
      font-weight: 500;
      margin-bottom: 12px;
      @include mixins.hh(25, 22, 18, 18, black, "w-light");
      width: 70%;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 25px;
      @include mixins.respond(md) {
        width: 100%;
        margin-bottom: 35px;
      }
    }
  }
  background-color: $gray;

  display: flex;
  flex-direction: column;
@include mixins.respond(md) {
    margin-top: 30px;
    padding-top: 20px;
  }
  &_top {
    margin-bottom: 55px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mixins.respond(md){
     margin-top: 0px
    }
    p {
      @include mixins.hh(17, 17, 17, 17, #333632, "w-light");
    }
    h1 {
      margin-top: 15px;
      font-weight: 500;
      @include mixins.hh(55, 40, 26, 26, black, "w-light");
      @include mixins.respond(md){
        width: 70%;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
  &-content {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0px;
    grid-column-gap: 10px;

    @include mixins.respond(md) {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 0px;
      grid-row-gap: 40px;
      
    }
  }
  &-box {
    height: 90%;
    overflow: hidden;
    &:hover .home_activity-box-hover {
      transform: scale(1.02);
    }
    &-hover {
      transition: transform 0.5s, filter 1.5s ease-in-out;
      @include mixins.bgimg;
      border: 1px solid black;
      height: 100%;
      padding: 10px !important;
    }
    @include mixins.respond(md) {
      height: 400px;
    }

    &-container {
      border: 1px solid $buttonHover;
      height: 100%;
      display: flex;
      justify-content: end;
      align-items: start;
      flex-direction: column;
      padding: 15px;
      padding-bottom: 30px;
      position: relative;
      @include mixins.respond(md) {
      }
      span {
        width: 90%;
        margin-bottom: 12px;
        line-height: 23px;
        text-transform: uppercase;
        @include mixins.hh(17, 17, 17, 17, white, "w-light");
        @include mixins.respond(md) {
          text-transform: capitalize;
        }
      }
      p {
        width: 90%;
        margin-bottom: 12px;
        line-height: 23px;
        @include mixins.hh(17, 17, 17, 17, white, "w-light");
      }
      h1 {
        font-weight: 500;
        @include mixins.hh(35, 30, 25, 25, white, "w-light");
      }
      a {
        z-index: 12;
        background-color: transparent;
        font-size: 11px;
        border: none;
        position: relative;
        color: #ffffff;
        cursor: pointer;
        border: none;
        text-decoration: none;
        margin-top: 20px;
        text-transform: uppercase;
        padding-bottom: 6px;
        svg {
          transition: transform 0.2s ease;
          transform-origin: 0 0;
          height: 10px;
          transform: translateX(6px);
        }
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background: $buttonHover !important;

          content: "";
          transition: transform 0.2s ease;
          transform-origin: 0 0;
        }
        &:hover svg {
          transition: transform 0.2s ease;
          transform-origin: 0 0;
          transform: translateX(13px);
        }
      }

      a:hover:before {
        transform: scaleX(0);
      }

      &-odd {
        position: relative;
        overflow: hidden;

        &:hover img {
          transform: scale(1.04);
          cursor: pointer;

          // @include mixins.slow;
        }

        img {
          height: 278px;
          width: 100%;
          transition: transform 0.5s, filter 1.5s ease-in-out;
        }
        &-content {
          padding: 30px;
          span {
            @include mixins.hh(15, 15, 15, 15, #333632, "w-light");
            margin-top: 15px;
            margin-bottom: 25px;
            text-transform: uppercase;
            @include mixins.respond(md) {
              text-transform: capitalize;
            }
            
          }
          p {
            @include mixins.hh(17, 17, 17, 17, #333632, "w-light");
            margin-top: 15px;
            margin-bottom: 25px;
          }
          h1 {
            font-weight: 500;
            margin-top: 15px;
            @include mixins.hh(35, 30, 25, 25, black, "w-light");
          }
          a {
            z-index: 12;
            background-color: transparent;
            font-size: 11px;
            border: none;
            position: relative;
            color: #333632;
            cursor: pointer;
            padding-bottom: 6px;
            border: none;
            text-decoration: none;
            margin-top: 20px;
            text-transform: uppercase;
            svg {
              transition: transform 0.2s ease;
              transform-origin: 0 0;
              height: 10px;
              transform: translateX(6px);
            }
            &::before {
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              background: $buttonHover !important;

              content: "";
              transition: transform 0.2s ease;
              transform-origin: 0 0;
            }
            &:hover svg {
              transition: transform 0.2s ease;
              transform-origin: 0 0;
              transform: translateX(13px);
            }
          }

          a:hover:before {
            transform: scaleX(0);
          }
  
        }
      }
    }
    &-odd {
      margin: 60px 30px 120px 30px;
      border: 1px solid black;
      @include mixins.respond(md) {
        grid-template-columns: repeat(1, 1fr);
        margin:0px;
      }
    }
  }
}
.home_testimonial {
  height: fit-content;
  padding: 0px;
  position: relative;
  .carousel-inner {
    height: fit-content;
    padding: 0px;
  }
  .carousel-item {
    padding: 300px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mixins.bgimg;
    text-align: center;
    color: white;
  }
  .carousel-caption {
    position: relative;
  }
}
.home_villa_link {
  text-decoration: none;
  color: unset;
}
.home_villa_img {
  position: relative;
  overflow: hidden;

  &:hover img {
    transform: scale(1.04);
    cursor: pointer;
  }
}

.home_villa_img img {
  transition: transform 0.5s, filter 1.5s ease-in-out;
}

.home_testimonial {
  .slick-dots {
    bottom: 30px;
  }
  .slick-slider {
    width: 100%;
    overflow: hidden;
  }
  .slick-dots li button:before {
    color: white !important;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
  .slick-slide img {
    display: block;
    height: 303px;
    width: 97%;
    object-fit: cover;
  }
  .testimonial-slide {
    height: 60vh;
    @include mixins.bgimg;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    p {
      width: 50%;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 20px;
      @include mixins.hh(27, 24, 22, 19, #ffffff, "w-light");
      @include mixins.respond(md){
        width: 90%;
      }
    }
    h3 {
      text-transform: uppercase;
      @include mixins.hh(17, 17, 17, 17, white, "w-light");
    }
  }
}

.room_similar_price {
  background-color: white;
  z-index: 15;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 7px 10px;
  width: fit-content;
  font-size: 14px;
}






input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.react-datepicker-wrapper input::placeholder{
  font-size: 15px;
}

.react-datepicker-wrapper input{
  padding: 3px 5px;
}


.home_hero {
  position: relative;
  height: calc(100vh - 80px);
  overflow: hidden;
  z-index: 0;
  background-image: url("../../Assets/placeholder.png");

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(19, 19, 19, 0.192), rgba(56, 56, 56, 0.322));
    z-index: 1;
  }

  &_video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  &_placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    visibility: visible;
  }

  &_video[autoplay] + &_placeholder {
    visibility: hidden;
  }

  &_content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;
  }

  &_button {
    display: flex;
    margin-top: 20px;

    button {
      margin-right: 10px;

      @include mixins.respond(md) {
        margin-bottom: 10px;
      }
    }

    @include mixins.respond(md) {
      flex-direction: column;
    }
  }
}


.villa_mobile{
  height: 100vh;
  @include mixins.respond(md) {
    height: 270px;
    margin-bottom: -20px;
      }
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    @include mixins.respond(md) {
      height: auto
        }
  }

}