.footer {
  background-color: #5c6753;
  color: #ffffff;
  padding: 20px 0;
  font-family: Arial, sans-serif;
}
.footer-ideaa{
  padding-left: 130px;
}

.footer .container {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  min-width: 220px;
  margin: 20px;
}

.footer-section h2, .footer-section h3 {
  margin-bottom: 10px;
  color: #ffffff;
}

.footer-section p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.6;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-section .social-icons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.footer-section .social-icons img {
  width: 24px;
  height: 24px;
}

.footer-section address {
  font-style: normal;
  line-height: 1.6;
}

.footer-section .get-directions {
  display: inline-block;
  margin-top: 10px;
  color: #e8b14c;
  text-decoration: none;
}

.footer-section .get-directions:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-ideaa{
    padding-left: 0px;
  }
  .footer .container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-section {
    margin: 0 0 20px 0;
    width: 100%;
  }
}
