@use "../../Assets/abstracts/mixins";
@import "../../Assets/abstracts/variables";

.Blog_hero {
  @include mixins.bgimg;
  height: calc(90vh - 180px);
  margin-top: 60px;
  @include mixins.respond(md) {
    height: calc(100vh - 70px);
  }
  &_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    h2 {
      font-weight: 500;
      @include mixins.hh(85, 65, 55, 35, white, "w-light");
      @include mixins.respond(md) {
        text-align: center;
      }
    }
    p {
      margin-top: 20px;
      @include mixins.hh(21, 19, 19, 18, white, "w-light");
      @include mixins.respond(md) {
        text-align: center;
      }
    }
    .main-btn {
      margin-top: 30px;
    }
  }

  &_checkAvaibility {
    background-color: #f8f5f1;
    &-wrapper {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 0;
      padding: 60px 0;

      padding: 40px 40px 40px 40px;

      @include mixins.respond(md) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &-box {
      display: flex;
      justify-content: start;
      align-items: center;

      padding: 10px 0;
      &:nth-child(1) {
        border-right: 1px solid $buttonHover;

        @include mixins.respond(md) {
          padding-left: 0px;
          border-right: none;
        }
      }
      &:nth-child(2) {
        border-right: 1px solid $buttonHover;
        padding-left: 20px;
        @include mixins.respond(md) {
          padding-left: 0px;
          border-right: none;
        }
      }
      &:nth-child(3) {
        padding-left: 20px;
        @include mixins.respond(md) {
          padding-left: 0px;
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        margin-left: 17px;
      }
      svg {
        width: 20px;
        height: auto;
        path {
          fill: $buttonHover;
        }
      }
      h5 {
        font-weight: 500;
        margin-bottom: 8px;
        @include mixins.hh(19, 19, 19, 19, $text, "w-light");
      }
      .main-btn {
        padding-left: 45px;
        padding-right: 45px;
      }
    }
  }
}
.Blog_welcome {
  background-color: white;
  padding: 100px 0px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0px;
    span {
      text-transform: uppercase;
      margin-bottom: 20px;
      @include mixins.hh(19, 19, 19, 19, $lightBlack, "w-light");
    }
    h2 {
      text-transform: uppercase;
      margin-bottom: 30px;
      width: 60%;
      line-height: 57px;
      margin: 0 auto;
      @include mixins.hh(40, 35, 35, 30, $lightBlack, "w-light");
    }

    p {
      width: 60%;
      margin: 0 auto;
      margin-top: 30px;
      @include mixins.hh(19, 19, 19, 19, $lightBlack, "w-light");
      margin-bottom: 35px;
    }
  }
  &_actions {
    display: flex;
    button {
      margin-right: 15px;
    }
  }
}

.Blog_banner {
  @include mixins.bgimg;
  padding: 100px 0px;
  @include mixins.respond(md) {
    height: calc(100vh - 70px);
  }
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    span {
      text-transform: uppercase;
      margin-bottom: 20px;
      @include mixins.hh(19, 19, 19, 19, white, "w-light");
    }
    h2 {
      margin-bottom: 25px;

      line-height: 57px;
      margin: 0 auto;
      @include mixins.hh(40, 35, 35, 30, white, "w-light");
    }

    p {
      margin: 0 auto;
      margin-top: 25px;
      @include mixins.hh(19, 19, 19, 19, white, "w-light");
      margin-bottom: 0px;
    }
  }
}
.Blog_roomSlide {
  position: relative;
  &_icon {
    transform: rotate(180deg);
    overflow: hidden;
    position: absolute;
    top: -26px;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    svg {
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      path {
        fill: white;
      }
    }
  }
  background-color: white;
  padding: 100px 0px;
  &-container {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 70px;
    grid-column-gap: 40px;
  }
  .home_gallery_top {
    height: fit-content;
    h4 {
      margin-bottom: 15px;
      @include mixins.hh(19, 19, 19, 19, black, "w-light");
    }
    h1 {
      margin-bottom: 25px;
      font-weight: 500;
      @include mixins.hh(65, 65, 40, 35, black, "w-light");
    }
    p {
      margin-bottom: 35px;
      line-height: 25px;
      width: 40%;
      @include mixins.hh(19, 19, 19, 19, black, "w-light");
      @include mixins.respond(md) {
        width: 100%;
      }
    }
  }
  body {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 60px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
  }
  h3 {
    font-size: 40px;
    background-color: red;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 150px 0;
    margin: 0px auto;
    font-family: sans-serif;
    margin: 0 50px;
  }
  .slick-next {
    position: absolute;
    top: -40px;
    right: 50px;
    font-size: 22px;
    &::before {
      font-size: 40px;
    }
  }
  .slick-prev {
    left: auto;
    position: absolute;
    top: -40px;
    font-size: 22px;
    right: 124px;
    &::before {
      font-size: 40px;
    }
  }
  .slick-dots {
    bottom: -65px;
  }

  img {
    display: block;
    height: 430px;
    width: 97%;
    object-fit: cover;
  }
  .home_villa_content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    h2 {
      margin-bottom: 10px;
      font-weight: 500;
      margin-top: 25px;
      @include mixins.hh(35, 35, 30, 25, #1a1b1a, "w-light");
    }
    p {
      line-height: 24px;
      width: 70%;
      @include mixins.hh(17, 17, 17, 17, #333632, "w-light");
    }
    a {
      border-bottom: 1px solid $buttonHover;
      text-decoration: none;
      margin-top: 10px;
      @include mixins.hh(19, 18, 18, 18, #333632, "w-light");
      @include mixins.slow;
      svg {
        height: 10px;
        @include mixins.slow;
      }
      &:hover {
        border-bottom: none;
        svg {
          margin-left: 8px;
        }
      }
    }
  }
  .home_villa_img {
    position: relative;
  }
  .home_villa_info {
    display: grid;
    width: 80%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0;
    margin-bottom: 10px;
    svg {
      margin-right: 6px;
      path {
        fill: #333632;
      }
    }
    .room_similar_price {
      background-color: white;
      z-index: 15;
      position: absolute;
      top: 15px;
      left: 15px;
      padding: 7px 10px;
      width: fit-content;
      font-size: 14px;
    }
  }
}

.Blog_similar {
  background-color: white;
  padding: 100px 0px;
  position: relative;
  padding-bottom: 100px;
  .home_gallery_top 
    h1 {
      margin-bottom: 25px;
      font-weight: 500;
      @include mixins.hh(65, 65, 40, 35, black, "w-light");
      @include mixins.respond(md) {
        margin-bottom: 15px;
      }
    }
  
  @include mixins.respond(md) {
    padding: 60px 0px;
  }

  &_icon {
    transform: rotate(180deg);
    overflow: hidden;
    position: absolute;
    top: -26px;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    svg {
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      path {
        fill: white;
      }
    }
  }
  &_price {
    background: rgba(255, 255, 255, 0.85);
    z-index: 15;
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 6px 15px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-weight: 600;
      @include mixins.hh(28, 28, 24, 24, #333632, "w-light");
      margin-bottom: 0px;
    }
    span {
      text-transform: uppercase;
      @include mixins.hh(14, 14, 14, 14, #333632, "w-light");
    }
    p {
      text-transform: uppercase;
      font-weight: 600;
      @include mixins.hh(28, 28, 24, 24, #333632, "w-light");
    }
  }
  &-content {
    width: 100%;
  }
  &-lists {
    display: grid;
    padding-top: 25px;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 45px;
    @include mixins.respond(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-item {
    height: 420px;
    position: relative;
    &-content {
      padding: 20px;
      align-items: start;
      height: 100%;
      justify-content: end;
      color: white;
    }
    &-more {
      padding: 20px;
      align-items: start;
      height: 100%;
      justify-content: end;
      color: white;
    }
  }

  .room_similar {
    background-color: white;
    padding: 60px 0px;
    &_price {
      background-color: white;
      z-index: 15;
      position: absolute;
      top: 15px;
      left: 15px;
      padding: 7px 10px;
      width: fit-content;
      font-size: 14px;
    }
    &-content {
      width: 100%;
    }
    &-lists {
      display: grid;
      margin-top: 45px;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 45px;
    }
    &-item {
      height: 420px;
      position: relative;
      &-content {
        padding: 20px;
        align-items: start;
        height: 100%;
        justify-content: end;
        color: white;
      }

      &-more {
        padding: 20px;
        align-items: start;
        height: 100%;
        justify-content: end;
        color: white;
      }
    }
  }

  .item-card {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 600px;
    border-radius: 0px;
    @include mixins.slow;
    .item-card-hover {
      width: 100%;
      height: 100%;
      @include mixins.slow;
      @include mixins.bgimg;
    }
    &:hover .item-card-hover {
      transform: scale(1.05);
    }
    &:hover .discover-more {
      opacity: 1;
    }
  }

  .card-image {
    position: relative;
    width: 100%;
    cursor: pointer;
    @include mixins.slow;
    height: 100%;
    :hover .item-card-hover {
      transform: scale(1.2);
    }
  }

  .image-front {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }

  .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background: #545d48d6;
    color: #fff;
    // transition: opacity 0.3s ease-in-out;
    @include mixins.slow;
  }

  .card-image:hover .hover-content {
    opacity: 1;
  }

  .card-image:hover .image-front {
    opacity: 0;
  }

  .card-image:hover .hover-text {
    bottom: 0px;
    @include mixins.slow;
  }

  .image-back {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }

  .hover-text {
    cursor: pointer;
    @include mixins.slow;
    position: absolute;

    padding: 30px;
    a {
      border-bottom: 1px solid $buttonHover;
      font-weight: 400;
      font-size: 15px;
      padding-bottom: 4px;
    }
  }

  .hover-text p {
    margin: 10px 0;
  }

  //   .discover-more {
  //     color: white;
  //     text-decoration: none;
  //     font-weight: 500;
  //     border-bottom:1px solid $lightbrown;
  //   }

  a {
    z-index: 12;
    background-color: transparent;
    font-size: 13px;
    border: none;
    position: relative;
    color: white;
    cursor: pointer;
    padding-bottom: 6px;
    border: none;
    text-decoration: none;
    margin-top: 20px;
    opacity: 0;
    text-transform: capitalize;
    svg {
      transition: transform 0.2s ease;
      transform-origin: 0 0;
      height: 10px;
      transform: translateX(6px);
    }
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: $buttonHover !important;

      content: "";
      transition: transform 0.2s ease;
      transform-origin: 0 0;
    }
    &:hover svg {
      transition: transform 0.2s ease;
      transform-origin: 0 0;
      transform: translateX(13px);
    }
  }
  a:hover:before {
    transform: scaleX(0);
  }

  .card-details {
    padding: 15px;
    opacity: 1;
    transition: transform 0.4s;
    position: absolute;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    color: white;
    padding: 20px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    left: 8px;
    bottom: -38px;
    width: 80%;
    h2 {
      margin-top: 8px;
      @include mixins.hh(30, 30, 30, 27, white, "w-light");
    }
  }

  .card-details p {
    margin: 5px 0;
  }

  .card-image:hover .card-details {
    @include mixins.slow;

    transform: translateY(-50px);
  }
}

.Blog_bannerBottom {
  @include mixins.bgimg;
  padding: 100px 0px;
  @include mixins.respond(md) {
    height: calc(100vh - 70px);
  }
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    span {
      text-transform: uppercase;
      margin-bottom: 20px;
      @include mixins.hh(19, 19, 19, 19, white, "w-light");
    }
    h2 {
      margin-bottom: 25px;

      line-height: 57px;
      margin: 0 auto;
      @include mixins.hh(40, 35, 35, 30, white, "w-light");
    }

    p {
      width: 80%;

      text-align: center;
      margin: 0 auto;
      margin-top: 25px;
      @include mixins.hh(19, 19, 19, 19, white, "w-light");
      margin-bottom: 0px;
    }
  }
  .Blog-checkIn {
    display: flex;
    margin-top: 35px;
    align-items: center;
    color: white;
    > div {
      margin-right: 20px;
      &:first-child {
        border-right: 1px solid white;
        padding-right: 20px;
      }
      svg {
        margin-right: 6px;
        path {
          fill: $buttonHover;
        }
      }
    }
  }
}

.blog_gallery{
  padding: 100px 0px;
  p{

    width: 100% !important;
  }
  @include mixins.respond(md) {
    padding: 30px 0px;
  }

 .slick-slide img{
height: 430px !important;
@include mixins.respond(md) {
height: 330px !important;
}
  }
}

.blog_gallery .home_gallery_top{
  margin-bottom: 25px;
  @include mixins.respond(md) {
    padding-top: 20px;
  }
}

.image-grid-section {
  padding: 40px 20px;
  background-color: #f8f5f1;

  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @include mixins.respond(md) {
      grid-template-columns: repeat(1, 1fr);
    }

    .image-item {
      display: flex;
      flex-direction: column;
      align-items: center;


      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-width: 100%;
        border-radius: 0px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      }

      p {
        margin-top: 15px;
        font-size: 16px;
        color: #4e514e;
        line-height: 1.5;
      }
    }
  }
}
