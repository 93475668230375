@use "../../Assets/abstracts/mixins";
@import "../../Assets/abstracts/variables";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header {
  background-color: white;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #00000014;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 0px 20px 0px;
  position: relative;
  
}
.header button{
border:none !important;
background-color: transparent !important;
text-transform: uppercase !important;
border-right:1px solid rgb(121, 118, 118) !important;
padding: 0px 6px !important;
&:last-child{
  border-right:0px !important
}
}

.activeLang{
  font-weight: bold;
}
.logo img {
  width: 120px;
  height: 56px;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-bottom: 0px;
  align-items: center;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  color: #545554;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
  border-bottom: 1px solid #545554;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 992px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 0px;
  }
}

@media (max-width: 992px) {
  .logo {
    height: auto;
  }
  .logo img {
    width: 113px;
    height: 50px;
  }

  .header{
    padding: 15px 0px 15px 0px
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    z-index: 1222;
    font-size: 22px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 0px;
    background-color: #fef7e5;
    width: 0px;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 100vw;
    z-index: 122;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    margin-top: 74px;
    padding-left: 0px;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

.main-btn {
  @include mixins.button;
}

.border-btn {
  @include mixins.borderButton;
}
.check-btn {
  @include mixins.button;
  padding: 0px 20px !important;
  font-size: 15px !important;
  height: 44px !important;
  display: flex;
  align-items: center;

  a {
    text-transform: capitalize !important;
    color: white !important;
    font-size: 14px !important;
    text-decoration: none;
    font-weight: normal !important;
  }

  
}


body.nav-open {
  overflow: hidden;
}

.nav-elements ul li.activeMenu a {
  color: #8f6d3c; 
  font-weight: bold;
  border-bottom: 2px solid #8f6d3c; 
}


.header {
  background-color: white;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #00000014;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 0px 20px 0px;
  position: fixed; /* Make the header fixed */
  top: 0; /* Ensure it's at the top */
  width: 100%; /* Full width */
  z-index: 1000; /* Make sure it's above other content */
}

// body {
//   margin-top: 100px; /* Add a top margin to avoid content overlapping the fixed header */
// }

@media (max-width: 992px) {
  body {
    margin-top: 80px; /* Adjust margin for smaller screens */
  }

  .header {
    padding: 15px 0px; /* Adjust padding for smaller screens */
  }
}
