@use "../../Assets/abstracts/mixins";
@import "../../Assets/abstracts/variables";

.house-rules {

    padding: 70px 0px;

    font-family: Arial, sans-serif;

}

h2 {
    font-size: 24px;
    margin-bottom: 10px;

}

p {
    font-size: 14px;
    line-height: 1.5;

}
.house-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2 {
        margin-bottom: 10px;
        @include mixins.hh(40, 35, 35, 24, white, "");
   
    
    }
    
    p {
        color:white;
        font-size: 14px;
        line-height: 1.5;
    
    }
}
.rule-section {
    margin-top: 20px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-top: 20px;
    @include mixins.respond(md) {
        grid-template-columns: repeat(1, 1fr);
           }
}

.rule-item {
    display: flex;
    align-items: flex-start;
    padding: 20px;
}

.rule-item span {
    font-size: 20px;
    margin-right: 15px;
    color:white;
}

.rule-item h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 5px;
    color:white;
}

.rule-item p {
    margin: 0;
    color:white;
}

.rule-item a {
    color: white;
    text-decoration: none;
}

.rule-item a:hover {
    text-decoration: underline;
}
